import React from 'react';

export const Intro = () => (
    <div>
        <div>
            #Choose your option.<br/>
            1. About
            2. Project
            3. Technology
            4. Contact
            Q. Quit current page <br/>
        </div>
    </div>
)


