import React from 'react';

export const Tech =() => (
    <>
        <div>#Frontend Development</div>
        <div>React | NextJS | NestJS | ReactJS | Typescript </div><br/>
        <div>#Backend Development</div>
        <div>Nodejs | Python</div><br/>
        <div>#CMS | CRM</div>
        <div>Wordpress | Salesforce</div>
    </>
)