import React from 'react';

export const Contact =() => (
    <div>
        Contact
        <br/>Phone: <a href="tel:+31615197160">Phone</a>
        <br/>Email: <a href="mailto:sreenishd@gmail.com">Email</a>
    </div>

)


