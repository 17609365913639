import React from 'react';
import Intro from '../intro'

import {TerminalController} from '../terminal/index'

function App() {
  return (
    <div className="App">
      <TerminalController />
    </div>
  );
}

export default App;
