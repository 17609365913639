import React,{ useEffect, useState} from 'react';
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui';
import { About } from './about';
import { Project } from './project';
import { Tech } from './tech';
import { Intro } from './intro';
import { Contact } from './contact';
import { Others } from './others';

export const TerminalController = (props = {}) => {
  const [output, setOutput] = useState<(q?:Element)=> void>(Intro);
  const [input, setInput] = useState("");
  useEffect(() => {
    const lowerInput = input.toLowerCase();
    console.log('lower input', lowerInput, typeof(lowerInput));
    if(lowerInput === '1' || lowerInput  === 'about' ){
      setOutput(About)
    }
    if(lowerInput === '2' || lowerInput === 'project'){
      setOutput(Project)
    }
    if(input === '3' || lowerInput === 'tech'||  lowerInput === 'technology'){
      setOutput(Tech)
    }
    if(input === '4' ||  lowerInput === 'contact'){
      setOutput(Contact)
    }
    if(input === 'q' ||  lowerInput === 'quit' ||  lowerInput === ''){
      setOutput(Intro)
    }
  },[input])

  // setInput()
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         const t = new Date(new Date().toLocaleString('en', {timeZone: 'Europe/Amsterdam'}));
    //         const hours = t.getHours()
    //         const minutes = t.getMinutes()
    //         const seconds = t.getSeconds()
    //         const myTime1 = minutes < 10 ? "0" + minutes : minutes
    //         const myTime2 = seconds < 10 ? "0" + seconds : seconds
    //         const myTime3 = hours > 11 ? "PM" : "AM"
    //         var t_str = hours + ":" + myTime1 + ":" + myTime2 + " " + myTime3;
    //         setTime(t_str);
    //     }, 1000);
    //     return () => clearInterval(interval);
    // }, []);
   
    // setOutput(intro)
  // const [terminalLineData, setTerminalLineData] = useState([
  //   <TerminalOutput>{output}</TerminalOutput>
  // ]);

  return (
    <div className="container">
      <Terminal name='Welcome!!!' 
        colorMode={ ColorMode.Light }  
        onInput={ terminalInput =>  setInput(terminalInput) }>
        <TerminalOutput>{output}</TerminalOutput>
      </Terminal>
    </div>
  )
};
