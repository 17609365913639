import React from 'react';

export const About = () => (
    <div>
        I'm Sreenish Damodaran. I'm a Full-stack developer and proud Linux user. <br/>
        Focused on building web solutions for real-life problems, most of the time using JS<br/>
        Born in  India lives in Netherlands with my wife and kid. Works at Brenntag.<br/>
        I am a full-stack developer with React and typescript is my passion.<br/>
    </div>
)


